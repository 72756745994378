import { Layout } from "antd";
import Cookie from "js-cookie";
import React from "react";

import Auth from "@/lib/auth";

const { Content } = Layout;
class Login extends React.Component {
  public componentDidMount() {
    Auth.login();
  }

  public render() {
    return <Content />;
  }
}

Login.getLayout = (page) => page;

export default Login;
